<template>
  <div style="width: 100%; height: 100%">
    <ag-grid-vue
        ref="scrollContainer"
        style="width: 100%; height: 800px;"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"

        @grid-ready="onGridReady"
        :rowModelType="rowModelType"
        :cacheBlockSize="cacheBlockSize"
        @body-scroll-end="onScrollEnd"
        :suppressRowVirtualisation="suppressRowVirtualisation"
    ></ag-grid-vue>
  </div>
</template>
<script>
import {AgGridVue} from "@ag-grid-community/vue3";
import {ModuleRegistry} from "@ag-grid-community/core";
import {ServerSideRowModelModule} from "@ag-grid-enterprise/server-side-row-model";
import EntityApi from "@/api/entityApi";

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
]);
import {LicenseManager} from "@ag-grid-enterprise/core";

LicenseManager.setLicenseKey(
    "CompanyName=Modern-Expo,LicensedGroup=Oleksandr Astafiev,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-026354,ExpiryDate=17_March_2023_[v2]_MTY3OTAxMTIwMDAwMA==75d0e08ce366f10efb524c0b51434bc4"
);

export default {
  name: "Table",
  components: {
    AgGridVue,
  },
  data() {
    return {
      entity:'receipt',
      columnDefs: [
        {
          field: "id",
          headerName: "Id"
        },
        {
          field: "date",
          headerName: "Date",
        },
        {
          field: "company",
        },
        {
          field: "conveniq",
        },
        {
          field: "terminal",
          headerName: "Terminal"
        },
        {
          field: "prro",
          headerName: "Prro"
        },
        {
          field: "customer",
        },
        {
          field: "total_amount",
          headerName: "Total Amount"
        },
        {
          field: "full_amount",
          headerName: "Full Amount"
        },
        {
          field: "paid_amount",
          headerName: "Paid Amount"
        }
      ],

      gridApi: null,
      columnApi: null,
      gridOptions: {
        context: {
          instance: this,
          tabletype: "primary",
        },
      },
      defaultColDef: {
        flex: 1,
      },
      getRowId: null,
      rowData: null,
      documentHeight: null,
      domLayout: '',

      rowModelType: "serverSide",
      serverSideDatasource: null,
      paginationPageSize: 20,

      cacheBlockSize: 50,
      maxBlocksInCache: 10,
      cacheOverflowSize: 2,
      maxConcurrentDatasourceRequests: 1,
      infiniteInitialRowCount: 20,
      rowBuffer: 0,

      countRowsLoaded: 500,
      lastPage: 0,
      positionScroll: 0,
      limitScroll: 1800,

      detailCellRendererParams: null,
      suppressRowVirtualisation:true

    };
  },

  watch: {},
  computed: {},
  methods: {
    onFirstDataRendered(params) {
      if (this.row.length === 0) {
        params.api.showNoRowsOverlay();
      } else {
        params.api.hideOverlay();
      }
    },
    async onGridReady(params) {
      console.log("1 onGridReady******", params);
      this.gridApi = params.api;
      this.gridcolumnapi = params.gridcolumnapi;
      this.updateData(params);
    },
    async updateData (params)  {
      let datasource = this._getServerSideDatasource();
      console.log("3 !!! datasource", datasource);
      await params.api.setServerSideDatasource(datasource);
    },
    _getServerSideDatasource() {
      console.log("2 Init ServerSideDatasource");
      return {
        getRows: (params) => {
          console.log('GET Rows start: ', params.request);
          EntityApi.getEntityItemsLimit(this.entity, this.lastPage, this.countRowsLoaded, null, true)
              .then((response) => {
                console.log("GET Rows end:", response);
                if (response.success) {
                  // supply rows for requested block to grid
                  params.success({
                    rowData: response.rows,
                    rowCount: response.lastRow,
                  });
                  //params.successCallback(response.rows, response.lastRow);
                  //console.log('params.success: ', params);
                } else {
                  params.failCallback(console.log("2 error****"));
                }
              }).catch(error => {
            console.log("2 error", error);
          })
        }
      }

    },
   /* async onBodyScroll(params) {
      const bottom_px = params.api.getVerticalPixelRange().bottom;
      const grid_height = params.api.getDisplayedRowCount() * params.api.getSizesForCurrentTheme().rowHeight;

      if (bottom_px === grid_height) {
        this.lastPage = this.lastPage + this.countRowsLoaded
        params.api.showLoadingOverlay();
        await EntityApi.getEntityItemsLimit(this.entity, this.lastPage, this.countRowsLoaded, null, true).then(
            (response) => {
              params.api.applyTransaction({
                add: response.rows,
              });
              params.api.hideOverlay();
            }
        );
      }
    },*/
  },
  mounted() {
    console.log("mounted base table");
  },
  unmounted() {
    console.log("un mounted base table");
  },
  beforeCreate() {
    console.log("beforeCreate base table");
  },
  created() {

  },
};
</script>
<style lang="scss">
</style>
