<template>
  <div class="v-row">
    <div v-if="settingListDevice && settingListDevice.length > 0"
         class="v-col-lg-12"
         style="padding: 10px 12px 12px 12px;">
      <settings :device="settingListDevice"
                :item="settingListItem"
                :countDay:="countDay"
      ></settings>
    </div>
    <component :is="nameComponent"
               :params="params"
               @hide-spinner="hideSpinner"></component>
  </div>
  <spinner v-if="spinnerShow"></spinner>
</template>
<script>

import {useMenuStore} from "@/stores/menuStore";
import Settings from "../components/dashboard/Settings.vue";
import Spinner from "./../components/Spinner.vue";

import {mapActions, mapState} from "pinia";
import {useDashboardStore} from "../stores/dashbordStore";

export default {
  name: "home Page",
  props: {},
  components: {
    Settings,
    Spinner
  },
  data() {
    return {
      spinnerShow: true,
      nameComponent: null,
      params: null,
      settingListDevice: [],
      settingListItem: [],
      countDay:1

    };
  },
  computed: {
    ...mapState(useMenuStore, ["menu"]),
    ...mapState(useDashboardStore, ['settingDashboard']),
  },
  methods: {
    ...mapActions(useDashboardStore, ['getSettingsDashboard', 'setSettingDashboard']),

    // Set setting dashboard (device, item)
    async setDataSettingsDashboard() {
      await this.setDataSettings();
    },
    async setDataSettings() {
      this.countDay = 1;
      this.getSettingsDashboard().then((data)=>{
        const device = data.conveniq.reduce((acc, item) => {
          const category = item.name.trim();
          if (!acc[category]) {
            acc[category] = {
              id: category,
              checked: true,
              title: category
            };
          }
          return acc;
        }, []);
        this.settingListDevice = Object.values(device);
      });
    },
   findElement(arr, element) {
      for (const item of arr) {
        if (item.href === element) {
          return item;
        }
        if (item.items) {
          const foundInChildren = this.findElement(item.items, element);
          if (foundInChildren) {
            return foundInChildren;
          }
        }
      }
      return null;
    },
    hideSpinner() {
      this.spinnerShow = false;
    }
  },
  mounted() {
    if (this.$route?.params?.name.length > 0) {
      const componentName = this.$route.params.name;
      import(`../components/dashboard/Pages/${componentName}.vue`)
          .then(module => {
            this.$options.components[componentName] = module.default;
            this.nameComponent = componentName;
            const items = this.menu.map(item => this.findElement([item], `/dashboard/${componentName}`)).filter(item => item !== null);
            this.params = items[0].params;
          })
          .catch(error => {
            console.error("Error importing component:", error);
          });
    }
  },
};
</script>
<style lang="scss"></style>
